
<template>
  <div class="esqueci_senha">
    <ValidationObserver ref="form" class="row" @submit.prevent="loginStep()" tag="form">
      <div class="col-md-12 my-2">
        <p class="mb-0">
          Digite abaixo o código que foi enviado para o email:
          <strong>{{maskEmail(email)}}</strong>
        </p>
      </div>
      <ValidationProvider
        v-slot="{ errors, ariaMsg, classes }"
        rules="required"
        name="Codigo"
        tag="div"
        :bails="false"
        class="col-md-12 my-1"
      >
        <div>
          <input
            type="text"
            class="px-2 py-2 input_default form-control"
            v-model="code"
            placeholder="Insira o código de 3 digitos"
            :class="classes"
          />
          <span v-bind="ariaMsg" :class="classes">{{ errors[0] }}</span>
        </div>
      </ValidationProvider>
      <div class="col-md-12 my-1">
        <button type="submit" class="btn_default px-2 py-2" :disabled="disabled">
          <span
            class="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
            v-show="disabled"
          ></span>
          {{ !disabled ? "Validar" : "" }}
        </button>
      </div>
     
    </ValidationObserver>
    <a class="reenviar_cod" @click.prevent="esqueciSenha">
      Não recebeu o código?
      <strong>clique aqui</strong>
    </a>
  </div>
</template>

<script>
export default {
  data() {
    return {
      code: "",
      disabled: false
    };
  },
  mounted() {},
  methods: {
    loginStep() {
      this.$refs.form.validate().then(success => {
        if (success) {
          this.disabled = true;
          this.$store.dispatch("validaCode", this.code).then(resolve => {
            if (resolve) {
              this.disabled = false;

              this.$parent.gratientAnimator(
                "linear-gradient(to right, #f38235 0%, #e0a638 100%)"
              );
              this.$router.push("/trocar-senha");
            } else {
              this.$notify({
                group: "erros",
                type: "error",
                text: `<i class="icon ion-close-circled"></i>Código invalido`
              });
              this.disabled = false;
            }
          });
        }
      });
    },
    maskEmail(email) {
      var maskedEmail = email.replace(/([^@\.])/g, "*").split("");
      var previous = "";
      for (var i = 0; i < maskedEmail.length; i++) {
        if (i <= 1 || previous == "@") {
          maskedEmail[i] = email[i];
        }
        previous = email[i];
      }
      return maskedEmail.join("");
    },
    esqueciSenha() {
      this.$loading();
      this.$store.dispatch("gerateReset").then(resolve => {
        if (resolve) {
          this.$loading.close();
        }
      });
    }
  },
  computed: {
    email() {
      return this.$store.state.login.userLogin.email;
    }
  }
};
</script>

<style scoped>
p {
  color: #868686;
}
.reenviar_cod {
  color: #868686;
  font-size: 10px;
  cursor: pointer;
  transition: all 0.2s linear;
}
.reenviar_cod:hover {
  color: #2474b2;
}
</style>